import { createRouter, createWebHistory } from 'vue-router';
// import App from './App.vue';
import StudentDashboard from './components/student/StudentDashboard.vue';
import JoinClass from './components/student/class/JoinClass.vue';
import ViewClass from './components/student/class/ViewClass.vue';
import ViewLab from './components/student/lab/ViewLab.vue';
import ViewProgress from './components/student/ViewProgress.vue';
import TeacherDashboard from './components/teacher/TeacherDashboard.vue';
import AddClass from './components/teacher/class/AddClass.vue';
import EditClass from './components/teacher/class/EditClass.vue';
import TeacherViewAllClasses from './components/teacher/class/ViewAllClasses.vue';
import TeacherViewClass from './components/teacher/class/ViewClass.vue';
import StudentViewClass from './components/student/class/ViewClass.vue';
import StudentViewAllClasses from './components/student/class/ViewAllClasses.vue';
import CreateLab from './components/teacher/lab/CreateLab.vue';
import EditLab from './components/teacher/lab/EditLab.vue';
import ViewTeacherLab from './components/teacher/lab/ViewLab.vue';
import ViewTeacherLabProgress from './components/teacher/lab/ViewLabProgress.vue';
import ManageUsers from './components/teacher/ManageUsers.vue';
import LoginPage from './components/LoginPage.vue';
import DialogueComponent from './components/student/dialogue/DialogueComponent.vue';

import honeycombChatComponent from './components/honeycomb/dialogue/DialogueComponent.vue';
const routes = [
// {
//     path: '/',
//     name: 'app',
//     component: App,
// },
{
    path: '/',
    name: 'login',
    component: LoginPage,
},
{
    path: '/student/dashboard',
    name: 'studentDashboard',
    component: StudentDashboard,
},
{
    path: '/student/dialogue/:chatID',
    name: 'dialogue',
    component: DialogueComponent,
    props: true,
},
{
    path: '/join/:classID',
    name: 'joinClass',
    component: JoinClass,
    props: true,
},
{
    path: '/student/class/view',
    name: 'ViewStudentClass',
    component: ViewClass,
},
{
    path: '/student/lab/view',
    name: 'viewStudentLab',
    component: ViewLab,
},
{
    path: '/student/progress',
    name: 'ViewProgress',
    component: ViewProgress,
},
{
    path: '/teacher/dashboard',
    name: 'teacherDashboard',
    component: TeacherDashboard,
},
{
    path: '/teacher/class/add',
    name: 'addClass',
    component: AddClass,
},
{
    path: '/teacher/class/edit',
    name: 'editClass',
    component: EditClass,
},
{
    path: '/teacher/class/view/:id',
    name: 'viewTeacherClass',
    component: TeacherViewClass,
    props: true,
},
{
    path: '/teacher/class/viewAll',
    name: 'viewAllTeacherClass',
    component: TeacherViewAllClasses,
},
{
    path: '/teacher/lab/create/:classID',
    name: 'createLab',
    component: CreateLab,
    props: true,
},
{
    path: '/teacher/lab/edit',
    name: 'editLab',
    component: EditLab,
},
{
    path: '/teacher/lab/view/:labID',
    name: 'viewTeacherLab',
    component: ViewTeacherLab,
    props: true,
},
{
    path: '/teacher/lab/viewprogress/:labID',
    name: 'viewTeacherLabProgress',
    component: ViewTeacherLabProgress,
    props: true,
},
{
    path: '/teacher/users/manage',
    name: 'manageUsers',
    component: ManageUsers,
},



{
    path: '/student/class/view/:id',
    name: 'viewStudentClass',
    component: StudentViewClass,
    props: true,
},
{
    path: '/student/class/viewAll',
    name: 'viewAllStudentClass',
    component: StudentViewAllClasses,
},
// honeycomb route
{
    path: '/honeycomb/chat',
    name: 'honeycombChat',
    component: honeycombChatComponent,
}

]

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

export default router;