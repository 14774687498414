<template>
    <div>
      <nav class="navbar">
        <img src="person-icon.png" alt="Profile Icon">
        <ul class="nav-tabs">
          <li class="tab-item active">Labs</li>
          <li class="tab-item">Progress</li>
        </ul>
        <button class="menu-button"><img src="menu-icon.png" alt="Menu Icon"></button>
      </nav>
  
      <aside class="sidebar">
        <button class="add-class-btn">Add Class</button>
        <ul class="class-list">
          <!-- Repeat this list item for each class -->
          <li>class name</li>
        </ul>
      </aside>
  
      <section class="content-area">
        <table>
          <!-- Table Header -->
          <thead>
            <tr>
              <!-- Column Titles -->
              <th>Your Labs</th>
              <th>Engagement</th>
              <th>Understanding</th>
            </tr>
          </thead>
          <!-- Table Body -->
          <!-- Repeat this row for each lab entry -->
          <!-- Placeholder text should be replaced with actual data bindings -->
          <!-- Example: v-for loop to render rows based on data property 'labs' -->
          <tbody v-for="(lab, index) in labs" :key="'lab-' + index">
            <!-- Row Entry -->
            <tr>
              <!-- Lab Name Column -->
              <td>{{ lab.name }}</td>
              <!-- Engagement Score Column -->
              <td>{{ lab.engagementScore }}</td>
              <!-- Understanding Score Column -->
              <td>{{ lab.understandingScore }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </template>

<script>
export default {
    name: 'ViewProgress',
    data() {
    return {
      labs: [
        // Replace with actual lab data objects
        { name: 'Lab 1', engagementScore: 90, understandingScore: 85 },
        { name: 'Lab 2', engagementScore: 80, understandingScore: 70 },
        // Add more lab entries as needed
      ],
    };
  },
}
</script>
<style scoped>
/* Add your CSS styles here */
.navbar {
  /* Styles for the navigation bar */
}
/* Add styles for other components */
</style>