<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="main-content flex-1 p-6">
      <div class="mb-4">
        <h2 class="yourClasses text-1xl font-bold text-center text-accent mb-1">{{ className }} - Create Lab</h2>
        
        <transition-group name="fade" tag="div">
          <div v-for="(field, index) in fields" :key="field.key" :style="{ animationDelay: `${index * 0.5}s` }"
               :class="{ 'form-field-disabled': field.disabled }" class="form-field">
            <label class="field-label">{{ field.label }}</label>
            <div class="separator" :class="{ 'wide-separator': field.key === 'description' }"></div>
            <div v-if="field.key === 'subject'">
              <v-slider v-for="n in 3" :key="n" class="slider-input input-bordered" color="teal-lighten-1" :label="labels[n - 1]" 
                        v-model="formValues.subject[n - 1]"
                        :min="0" :max="100" :step="1"
                        :disabled="field.disabled"></v-slider>
            </div>
            <component v-else :is="field.component"
                       :class="['input', 'input-bordered', field.key === 'description' ? 'description' : '']"
                       :placeholder="field.placeholder"
                       v-model="formValues[field.key]"
                       @input="handleInput(field.key, $event.target.value)" />
            <div v-if="field.key === 'dueDateTime'">
              <v-date-picker v-model="formValues.dueDateTime" :width="500" :style="{ backgroundColor: datepickerBGColor, color: 'white'}" :disabled="field.disabled"></v-date-picker>
            </div>
          </div>
          
        </transition-group>
      </div>
      <div class="addClassButton">
        <button :disabled="isAddButtonDisabled" class="btn btn-accent w-full" @click="addLab">Add Lab</button>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';

export default {
  name: 'AddLab',
  data() {
    return {
      fields: [
        { key: 'name', label: 'Give your lab a name...', component: 'input', placeholder: 'Lab Name', disabled: false },
        { key: 'subject', label: 'Tune Socratique to your ideal settings', placeholder: 'Lab Settings', disabled: true },
        { key: 'description', label: 'Please provide the lesson content for your lab...', component: 'textarea', placeholder: 'Lab Notes', disabled: true },
        { key: 'dueDateTime', label: 'Select Due Date', disabled: true } // Add this line
      ],
      formValues: {
        name: '',
        subject: [50, 50, 50],
        description: '',
        dueDateTime: null  // Add this line
      },
      labels: [
        "Questioning",
        "Detail",
        "Persistence"
      ],
      classID: null,
      className: null,
      datepickerBGColor: '#121826'
    };
  },
  computed: {
    isAddButtonDisabled() {
      return !this.formValues.name.trim() || 
             !this.formValues.subject.every(value => value !== null && value !== undefined) || 
             !this.formValues.description.trim();
    }
  },
  methods: {
    handleInput(fieldKey, newValue) {
      if (fieldKey === 'subject' && Array.isArray(this.formValues[fieldKey])) {
        this.formValues[fieldKey][parseInt(newValue.index)] = newValue.value;
      } else {
        this.formValues[fieldKey] = newValue;
      }
      this.updateFieldStatus();
    },
    updateFieldStatus() {
      this.fields[1].disabled = !this.formValues.name.trim();
      this.fields[2].disabled = !this.formValues.subject.every(value => value !== null);
      this.fields[3].disabled = !this.formValues.description.trim(); // Make dueDateTime field dependent on description
    },

    async addLab() {
      const payload = {
        classID: this.classID,
        className: this.className,
        labName: this.formValues.name,
        lessonContent: this.formValues.description,
        questioning: this.formValues.subject[0],
        detail: this.formValues.subject[1],
        persistence: this.formValues.subject[2],
        dueDateTime: this.formValues.dueDateTime, // Include the due date in the payload
        teacherID: "michaelmcmahon"
      };
      try {
        const response = await axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/addLab', payload);
        console.log('Lab added successfully:', response.data);
        const newLabId = response.data.labID;
        console.log('New lab ID:', newLabId);
        this.clearForm();
        this.$router.push({ name: 'viewTeacherLab', params: { labID: newLabId } });
      } catch (error) {
        console.error('Error adding lab:', error);
      }
    },
    clearForm() {
      this.formValues.name = '';
      this.formValues.subject = [50, 50, 50]; 
      this.formValues.description = '';
      this.updateFieldStatus();
    }
  },
  mounted() {
    this.updateFieldStatus(); 
  },
  created() {
    this.classID = this.$route.params.classID;
    axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${this.classID}`)
      .then(response => {
        this.className = response.data.className;
      })
      .catch(error => {
        console.error("There was an error fetching the class:", error);
      });
  }
}
</script>


<style>
.separator {
  margin: 1rem auto;
  height: 5px;
  background-color: rgb(44, 201, 183);
  border-radius: 30px;
  width: 0;
  animation: slideIn 0.8s ease forwards;
}

@keyframes slideIn {
  from { width: 0; }
  to { width: 92%; }
}

.fade-move {
  transition: transform 0.5s;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.input {
  border-style: dashed;
  background-color: transparent;
  margin-top: 0.5rem;
  margin-left: 4%;
}

.description {
  width: 90%;
  height: 20rem;
  padding-top: 10px;
}

.form-field {
  margin-bottom: 1rem;
}

.field-label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-field-disabled .input {
  background-color: #dddddd0d; 
  cursor: not-allowed;
  width: 92%;
}
.form-field-disabled label {
  color: #ffffff; 
}
.v-date-picker {
  background-color: #121826;
}

.btn:disabled {
  background-color: #666; 
  cursor: not-allowed;
}
</style>