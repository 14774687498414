<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="main-content flex-1 p-6">
      <div v-if="labData" class="class-details">
        <h2 class="text-1xl font-bold text-center text-accent mb-6">{{ labData.labName }}</h2>
        <p><strong>Students Completed:</strong> {{ labData.studentsCompleted.length }}</p>
        <p><strong>Lesson Content:</strong> {{ labData.lessonContent }}</p>

        <div class="labs mt-5">
          <h3 class="text-sm text-center font-semibold mb-2 pb-5">Students</h3>
          <ul class="list-none p-0">
            <li v-for="student in studentsData" :key="student.id" class="lab-item bg-gray-800 p-4 mb-4 rounded-lg w-11/12 mx-auto flex justify-between items-center">
              <div>
                <strong class="block text-lg">{{ student.firstName + student.lastName}}</strong>
                <p class="text-sm"><strong>Email: </strong>{{ student.email }}</p>
              </div>
              <button class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200">View Progress</button>
            </li>
          </ul>
        </div>

        
      </div>
      <div v-else class="loading">
        Loading class details...
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ViewTeacherLab',
  data() {
    return {
      labData: null,
      studentsData: [],
      labs: []
    };
  },
  async created() {
    const LabID = this.$route.params.labID;
    console.log('Lab ID:', this.$route.params.labID);
    try {
      const response = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${LabID}`);
      this.labData = response.data;
      console.log('lab details:', this.labData);
      const students = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${this.labData.classID}/students`);
      this.studentsData = students.data;
      console.log('Student details:', this.studentsData);
    } catch (error) {
      console.error('Error fetching class details:', error);
    }
  }
}
</script>

<style scoped>
.students{
  height: 300px;
  background-color: rgb(26, 44, 60);
  border-radius: 20px;
  padding: 5px;
  width: 100%;
}
</style>
