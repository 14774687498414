<template>
  <div class="trackerComponentContainer">
    <div v-if="isLoading" class="loadingIcon"></div>
    <Knowledgegraph
      v-if="showKnowledge && !isLoading"
      title="Knowledge"
      :fill="fill"
      :gradient="selectedGradient"
      :line-width="lineWidth"
      :value="overallKnowledge"
      :padding="padding"
      :smooth="smooth"
      :show-xaxis="true"
      class="knowledgeGraph"
    />
    <p v-if="!showKnowledge && !isLoading">{{ knowledgePlaceholderText }}</p>
    
    <EngagementGraph
      v-if="showEngagement && !isLoading"
      title="Engagement"
      :fill="fill"
      :gradient="selectedGradient"
      :line-width="lineWidth"
      :value="overallEngagement"
      :padding="padding"
      :smooth="smooth"
      :show-xaxis="true"
      class="engageGraph"
    />
    <p v-if="!showEngagement && !isLoading">{{ engagePlaceholderText }}</p>

    <div v-if="isEvaluating" class="loadingIcon"></div>
    <div v-if="!isEvaluating && currentEvaluation" v-html="formattedEvaluation" class="evaluationContainer"></div>
    <p v-if="!isEvaluating && !currentEvaluation">{{ evaluationPlaceholderText }}</p>
  </div>
</template>

<script>
import EngagementGraph from './EngagementGraph.vue';
import Knowledgegraph from './KnowledgeGraph.vue';
import axios from 'axios';

export default {
  name: 'TrackerComponent',
  components: {
    EngagementGraph,
    Knowledgegraph
  },
  data() {
    return {
      currentChatID: '',
      overallEngagement: [],
      overallKnowledge: [],
      showEngagement: false,
      showKnowledge: false,
      isLoading: true,
      isEvaluating: true,
      engagePlaceholderText: 'Start chatting with Socratique to see your engagement throughout the conversation!',
      knowledgePlaceholderText: 'Start chatting with Socratique to see your knowledge throughout the conversation!',
      evaluationPlaceholderText: 'Continue chatting with Socratique to start evaluating your chat performance...',
      goodFeedbackArray: [],
      constructiveFeedbackArray: [],
      fill: false,
      selectedGradient: ['#6fa8dc', '#42b983', '#2c3e50'],
      lineWidth: 2,
      padding: 8,
      smooth: true,
      currentEvaluation: '',
    };
  },
  computed: {
    formattedEvaluation() {
      return this.currentEvaluation
        .replace(/### (.+)/g, '<strong>$1</strong>') // Bold headers
        .replace(/\n/g, '<br>'); // Line breaks
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.currentChatID = localStorage.getItem('newChatID');
      axios.all([
        axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/honeycomb/chat/${this.currentChatID}/engagementScores`),
        axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/honeycomb/chat/${this.currentChatID}/understandingScores`)
      ])
      .then(axios.spread((engagementResponse, knowledgeResponse) => {
        this.overallEngagement = engagementResponse.data;
        this.overallKnowledge = knowledgeResponse.data;

        this.showEngagement = this.overallEngagement.length > 1;
        this.engagePlaceholderText = this.overallEngagement.length === 0
          ? 'Start chatting with Socratique to see your engagement throughout the conversation!'
          : 'Continue chatting with Socratique to see your engagement throughout the conversation!';

        this.showKnowledge = this.overallKnowledge.length > 1;
        this.knowledgePlaceholderText = this.overallKnowledge.length === 0
          ? 'Start chatting with Socratique to see your knowledge throughout the conversation!'
          : 'Continue chatting with Socratique to see your knowledge throughout the conversation!';

        this.isLoading = false;
        this.isEvaluating = false;

        // Call evaluate method after fetching data
        if (this.overallKnowledge.length > 1){
          this.evaluate();
        }
        
      }))
      .catch(error => {
        console.error(error);
        this.isLoading = false;
        this.isEvaluating = false;
      });
    },
    evaluate() {
      this.isEvaluating = true;
      axios.post(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/evaluate`, {
        ChatID: this.currentChatID
      })
      .then(response => {
        this.currentEvaluation = response.data.content;
        this.isEvaluating = false;
      })
      .catch(error => {
        console.error(error);
        this.isEvaluating = false;
      });
    }
  }
};
</script>

<style scoped>
/* Main container setup */
.main-container {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fdf5e6; /* Creamish background */
}

/* Sidebar styling */
.sideBarlistingClasses {
  width: 100%;
  background-color: #222222;
  padding-left: 10px;
}

/* Chat container styling */
.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fdf5e6; /* Creamish background */
  transition: margin-left 0.5s ease;
  min-width: 70%;
}

/* Tracker component container */
.trackerComponentContainer {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  padding: 20px;
  width: 100%;
  align-items: start;
  background-color: #fdf5e6;
}

/* Class styling */
.class {
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
  cursor: pointer;
  width: 95%;
  background-color: #121212;
}

.classTitle {
  font-size: 1.1rem;
  font-weight: bold;
}

.classDescription {
  font-size: 0.9rem;
  color: #bbb;
}

/* Sidebar header styling */
.sideBarTitle {
  font-size: 1.3rem;
  font-weight: bold;
}

.sideBarHeader {
  padding: 10px;
  background-color: #222222;
}

/* Chat header styling */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #2c2c2c;
}

.labsHeader {
  font-weight: 600;
}

.header-title {
  color: #333; /* Darker text for better contrast */
  font-weight: bold;
  font-size: 1.5rem;
}

.progress-icon {
  color: white;
}

/* Message container styling */
.messages-container {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  animation: fadeIn 0.5s ease-in-out; /* Fading in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message {
  margin-bottom: 20px;
}

.Socratique {
  text-align: left;
}

.user {
  text-align: right;
}

/* Message content styling */
.message-content {
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  word-break: break-word;
  background-color: #333;
  position: relative;
  animation: fadeIn 0.5s ease-in-out; /* Fading in animation */
}

.trackerComponent {
  width: 0;
  transition: width 0.5s ease;
  overflow: hidden;
  grid-template-columns: 1fr;
}

.trackerComponent-visible {
  width: 100%;
  .sideBarlistingClasses {
    width: 30%;
  }
  .chat-container {
    min-width: none;
  }
}

.chat-container-shifted {
  max-width: 60%;
}

/* Progress button styling */
.progressButtonContainer {
  width: 50px;
  height: 50px;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-width: 1px;
  border-color: #6fa8dc;
}

/* Message content for different types */
.Socratique .message-content {
  background-color: #fdf5e6; /* Creamish background for Socratique */
  font-weight: 500;
  color: #333;
}

.user .message-content {
  background: linear-gradient(to right, #42b983, #6fa8dc);
  color: white;
}

/* Message details styling */
.message-details {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.user-details {
  justify-content: flex-end;
}

.message-sender {
  font-size: 0.75rem;
  color: #bbb;
}

.user-sender {
  background-color: #2c3e50;
  border-radius: 10px;
  padding: 2px 6px;
  color: white;
  font-weight: bold;
}

/* Input container styling */
.input-container {
  display: flex;
  padding: 16px;
  background-color: #222;
}

/* Message input styling */
.message-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 8px;
  border: none;
  border-radius: 20px;
  background-color: #2c3e50;
  color: white;
}

/* Send button styling */
.send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background: linear-gradient(to right, #6fa8dc, #42b983);
  color: white;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.send-button:hover {
  background: linear-gradient(to right, #5d92b4, #369f7b);
}

/* Loading icon */
.loadingIcon {
  width: 50px;
  height: 50px;
  border: 4px solid grey;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Evaluation container */
.evaluationContainer {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  word-wrap: break-word;
}
</style>
