<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="main-content flex-1 p-6">
      <div v-if="classData" class="class-details">
        <h2 class="text-2xl font-bold text-center text-accent mb-6">{{ classData.className }}</h2>
        <p><strong>Subject:</strong> {{ classData.classSubject }}</p>
        <p><strong>Description:</strong> {{ classData.classDescription }}</p>

        <div class="labs mt-5">
          <h3 class="text-sm text-center font-semibold mb-2 pb-5">Labs</h3>
          <ul class="list-none p-0">
            <li v-for="lab in labs" :key="lab.id" class="lab-item bg-gray-800 p-4 mb-4 rounded-lg w-11/12 mx-auto flex justify-between items-center">
              <div>
                <strong class="block text-lg">{{ lab.labName }}</strong>
                <p class="text-sm"><strong>Due on: </strong>{{ lab.dueDateTime }}</p>
                <p class="text-sm">{{ lab.Notes }}</p>
              </div>
              <button @click="startProgress(lab)" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200">Start Progress</button>
            </li>
          </ul>
        </div>

        <div class="completed-labs mt-8">
          <h3 class="text-sm text-center font-semibold mb-2 pb-5">Your Completed Labs</h3>
          <ul class="list-none p-0">
            <li class="lab-item bg-gray-800 p-4 mb-4 rounded-lg w-11/12 mx-auto flex justify-between items-center">
              <div>
                <strong class="block text-lg">Lab Name</strong>
                <p class="text-sm">Notes</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="loading text-center text-xl">
        Loading class details...
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ViewClass',
  data() {
    return {
      classData: null,
      studentsData: [],
      labs: []

    };
  },
  methods:{
    startProgress(lab) {
        axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/addChat', {
          chatID: '',
          labID: lab.id,
          engagementScore: 0,
          understandingScore: 0,
          dialogue: []
        }).then(response => {
          console.log('New chat:', response.data);
          var tempChatID = response.data.chatID;
          this.$router.push({ name: 'dialogue', params: { chatID: tempChatID }});
        });
        
        
      }
  },
  async created() {
    const classId = this.$route.params.id;
    console.log('Class ID:', this.$route.params.id);
    try {
      const response = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classId}`);
      this.classData = response.data;

      const labPromises = this.classData.labs.map(labId => axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${labId}`));
      const labResponses = await Promise.all(labPromises);
      this.labs = labResponses.map(response => response.data);

      this.labs.forEach(lab => {
        const date = new Date(lab.dueDateTime);
        lab.dueDateTime = date.toLocaleDateString('en-US', { 
          weekday: 'long', 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short'
        });
      });
      console.log('Labs:', this.labs);
      console.log('Student details:', this.studentsData);
    } catch (error) {
      console.error('Error fetching class details:', error);
    }
  }
}
</script>

<style scoped>
.students {
  height: 300px;
  background-color: rgb(26, 44, 60);
  border-radius: 20px;
  padding: 5px;
  width: 100%;
}
</style>
