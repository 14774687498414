<!-- TODO -->
<!-- TODO -->
<!-- TODO -->
<!-- i'll be doing this one you can ignore for now -->


<template>
    <div class="add-class-form">
      <h2>Join Class</h2>
      <form @submit.prevent="saveClass">
        <div class="form-group">
          <label for="classCode">Class code</label>
          <input type="text" id="classCode" v-model="classCode">
        </div>
        
        <button type="submit">Join</button>
       
      </form>
    </div>
  </template>

<script>
import axios from 'axios';

export default {
    name: 'JoinClass',
    created() {
      const classID = this.$route.params.classID;
      const studentID = localStorage.getItem('currentStudentID');
      axios.put(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/student/${studentID}/addClassID/${classID}`)
        .then(response => {
          console.log('Class joined successfully:', response.data);
          this.$router.push({ name: 'studentDashboard' });
        })
        .catch(error => {
          console.error('Error joining class:', error);
        });

    },
}
</script>