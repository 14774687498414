<template>
  <div class="main-container">
    <!-- <div class="sideBarlistingClasses">
      <div class="sideBar">
        <div class="sideBarHeader">
          <span class="sideBarTitle">Your Classes and Labs</span>
        </div>
        <div class="sideBarContent">
          <div class="class" v-for="(classItem, index) in classes" :key="`class-${index}`">
            <h1 class="classTitle">{{ classItem.classname }}</h1>
            <div v-if="classItem.labs.length > 0">
              <h1 class="labsHeader">Labs</h1>
              <div class="lab" v-for="(lab, labIndex) in classItem.labs" :key="`lab-${labIndex}`">
                <h2>{{ lab.notes }}</h2>
              </div>
            </div>
            <div v-else>
              <p>No labs available for this class.</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  <!-- First Popup: Welcome -->
<div v-if="showPopup" class="popup-overlay">
  <div class="popup-container">
    <h2 class="popup-header">Welcome to Your SOCRATIQUE Interview</h2>
    <p class="popup-details">
      Before you start, please take note of the following:
    </p>
    <ul class="popup-list">
      <li>Ensure you have a stable internet connection.</li>
      <li>You will have 10 mins max to complete your interview! </li>
      <li>Your answers are recorded. So try your best! </li>
    </ul>
    <button class="popup-button" @click="startLesson">Start</button>
  </div>
</div>

<!-- Second Popup: Lesson Details -->
<div v-if="showLessonDetailsPopup" class="popup-overlay">
  <div class="popup-container">
    <h2 class="popup-header">Lesson Details</h2>
    <p class="popup-details">
      In this lesson, you will be asked questions about the following topics:
    </p>
    <ul class="popup-list">
      <li>X</li>
      <li>Y</li>
      <li>Z</li>
    </ul>
    <p class="popup-details">
     Press BEGIN to start your lesson.
    </p>
    <button class="popup-button" @click="beginLesson">Begin Lesson</button>
  </div>
</div>
    <div class="trackerComponent" :class="{ 'trackerComponent-visible': showProgressTracking }">
      <tracker-component v-if="showProgressTracking" :key="trackerKey"></tracker-component>
    </div>
    <div class="chat-container" :class="{ 'chat-container-shifted': showProgressTracking }">
      <div class="chat-header">
        <span class="header-title">{socratique interview title}</span>
        <div class="progressButtonContainer" @click="openProgressTrackingComponent">
          <svg-icon type="mdi" :path="path"></svg-icon>
        </div>
      </div>
      <div class="messages-container">
        <div v-for="message in messages" :key="`message-${message.id}`" :class="['message', message.role]">
          <span class="message-content">{{ message.messageContent }}</span>
          <div class="message-details" :class="message.role + '-details'">
            <span class="message-sender">{{ message.role }}</span>
          </div>
        </div>
      </div>
      <div class="input-container">
        <input 
          v-model="newMessage" 
          type="text" 
          placeholder="Type a message..." 
          class="message-input" 
          @keydown.enter="sendNewMessage"
        />
        <button @click="sendNewMessage" class="send-button">SEND</button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiFinance } from '@mdi/js';
import TrackerComponent from './progressTracking/TrackerComponent.vue';

export default {
  name: 'honeycombChatComponent',
  props: {
    Chat: String
  },
  data() {
    return {
      showPopup: true,            // Controls the visibility of the first popup
    showLessonDetailsPopup: false,  // Controls the visibility of the second popup
    showProgressTracking: false,
    studentId: "ESbn8ihNr09OF1Xg70Nu",
      path: mdiFinance,
      currentChatID: '',
      newMessage: '',
      messages: [],
      classes: [],
      trackerKey: 0,
      currentAssistantMessage: null, // Track the current assistant message being constructed
      newChatID: ''
    };
  },
  components: {
    SvgIcon,
    TrackerComponent,
  },
  mounted(){

  },
  created() {
    

    const newChat = axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/honeycomb/addChat', {
      ChatID: '',
      LabID: "u6aG8DqTfyuhezqrOcej",
      engagementScore: 0,
      understandingScore: 0,
      dialogue: []
    });

    newChat.then(response => {
      localStorage.setItem('newChatID', response.data.ChatID);
    }).catch(error => {
      console.error(error);
    });

    axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/student/${this.studentId}/classes`)
      .then(response => {
        if (response.data.length > 0) {
          this.classes = response.data.map(classItem => ({
            ...classItem,
            labs: []
          }));

          return Promise.all(this.classes.map(classItem =>
            axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/labs/${this.studentId}/${classItem.classID}`)
              .then(labResponse => {
                classItem.labs = labResponse.data.map(lab => ({
                  id: lab.id,
                  persistence: lab.Persistence,
                  labsID: lab.LabID,
                  teacherID: lab.TeacherID,
                  notes: lab.Notes,
                  detail: lab.Detail,
                  studentID: lab.StudentID,
                  questioning: lab.Questioning,
                  classID: lab.ClassID,
                  chatID: lab.chatID
                }));
              })
              .catch(labError => {
                console.error('Error fetching labs for class', classItem.classID, labError);
              })
          ));
        }
      })
      .then(() => {
        console.log('All labs loaded for all classes:', this.classes);
      })
      .catch(error => {
        console.error('Error fetching classes:', error);
      });
    this.currentChatID = localStorage.getItem('newChatID');
    // Load chats from firebase via API
    axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/honeycomb/chat/${this.currentChatID}/messages`)
      .then(response => {
        console.log(response.data, 'response');
        this.messages = response.data;
      });
  },
  methods: {
    startLesson() {
    this.showPopup = false;           // Hide the first popup
    this.showLessonDetailsPopup = true; // Show the second popup
  },
  beginLesson() {
    this.showLessonDetailsPopup = false; // Hide the second popup, starting the lesson
  },
    openProgressTrackingComponent() {
      this.showProgressTracking = !this.showProgressTracking;
    },
    async sendNewMessage() {
      if (this.newMessage.trim() !== '') {
        const TextFromBox = this.newMessage;
        this.newMessage = '';
        this.messages.push({
          messageContent: TextFromBox,
          messageNumber: this.messages.length + 1,
          role: 'user'
        });

        try {
          const response = await axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/honeycomb/chat', {
            message: TextFromBox,
            ChatID: localStorage.getItem('newChatID'),
          });

          const { sessionId } = response.data;

          const eventSource = new EventSource(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/honeycomb/chat/stream/${sessionId}`);

          this.currentAssistantMessage = {
            messageContent: '',
            messageNumber: this.messages.length + 1,
            role: 'Socratique'
          };
          this.messages.push(this.currentAssistantMessage);

          eventSource.addEventListener('message', (event) => {
            if (event.data === '[DONE]') {
              eventSource.close();
              this.trackerKey += 1; // Update tracker key to force re-mount
              this.currentAssistantMessage.messageContent = this.cleanMessage(this.currentAssistantMessage.messageContent);
              this.currentAssistantMessage = null; // Reset the current assistant message
              return;
            }
            
            try {
              const data = JSON.parse(event.data);
              if (data.content) {
                this.currentAssistantMessage.messageContent += data.content;
              }
              
            } catch (error) {
              console.error('Error parsing message data:', error);
            }
          });

          eventSource.addEventListener('error', (error) => {
            console.error('EventSource error:', error);
            eventSource.close();
            this.currentAssistantMessage = null; // Reset the current assistant message
          });
        } catch (error) {
          if (error.code === 'ECONNABORTED') {
            console.error('Request timeout:', error);
          } else if (error.response) {
            console.error('Server responded with status:', error.response.status);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error setting up request:', error.message);
          }
        }
      }
    },
    cleanMessage(message) {
      const index = message.toLowerCase().indexOf('engagement');
      return index !== -1 ? message.substring(0, index).trim() : message;
    }
  }
};
</script>


<style scoped>
/* Main container setup */
.main-container {
  display: flex;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  background-color: #add8e6; /* Light blue background */
}

/* Sidebar styling */
.sideBarlistingClasses {
  width: 100%;
  
  padding-left: 10px;
}

/* Chat container styling */
.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #add8e6; /* Light blue background */
  transition: margin-left 0.5s ease;
  min-width: 70%;
}

/* Class styling */
.class {
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
  cursor: pointer;
  width: 95%;
  
}

.classTitle {
  font-size: 20px;
  font-weight: bold;
}

.classDescription {
  font-size: 20px;
  color: #bbb;
}

/* Sidebar header styling */
.sideBarTitle {
  font-size: 20px;
  font-weight: bold;
}

.sideBarHeader {
  padding: 10px;
}

/* Chat header styling */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
 
}

.labsHeader {
  font-weight: 600;
}

.header-title {
  /* Darker text for better contrast */
  font-weight: bold;
  font-size: 20px;
}

.progress-icon {
  color: white;
}

/* Message container styling */
.messages-container {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  animation: fadeIn 0.5s ease-in-out; /* Fading in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message {
  margin-bottom: 20px;
}

.Socratique {
  text-align: left;
}

.user {
  text-align: right;
}

/* Message content styling */
.message-content {
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  word-break: break-word;
  position: relative;
  animation: fadeIn 0.5s ease-in-out; /* Fading in animation */
}

.trackerComponent {
  width: 0;
  transition: width 0.5s ease;
  overflow: hidden;
  grid-template-columns: 1fr;
}

.trackerComponent-visible {
  width: 100%;
  .sideBarlistingClasses {
    width: 30%;
  }
  .chat-container {
    min-width: none;
  }
}

.chat-container-shifted {
  max-width: 60%;
}

/* Progress button styling */
.progressButtonContainer {
  width: 50px;
  height: 50px;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-width: 1px;
  border-color: #6fa8dc;
}

/* Message content for different types */
.Socratique .message-content {
  background-color: #add8e6; /* Light blue background for Socratique */
  font-weight: 500;
  color: #333;
  font-size: 20px
}

.user .message-content {
  background-color: #003366; /* Dark blue for user messages */
  color: white;
}

/* Message details styling */
.message-details {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.user-details {
  justify-content: flex-end;
}

.message-sender {
  font-size: 20px;
  color: #bbb;
}

.user-sender {
  border-radius: 10px;
  padding: 2px 6px;
  color: white;
  font-weight: bold;
}

/* Input container styling */
.input-container {
  display: flex;
  padding: 16px;
}

/* Message input styling */
.message-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 8px;
  border: 1px solid black; /* Black border */
  border-radius: 20px;
  background-color: white; /* White background */
  color: black;
}

/* Send button styling */
.send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #003366; /* Solid dark blue */
  color: white;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.send-button:hover {
  background-color: #002244; /* Slightly darker blue on hover */
}
/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Popup container */
.popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Popup header */
.popup-header {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

/* Popup details */
.popup-details {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

/* Popup list */
.popup-list {
  text-align: left;
  margin-bottom: 20px;
  padding-left: 20px;
}

/* Popup button */
.popup-button {
  padding: 10px 20px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.popup-button:hover {
  background-color: #002244;
}

</style>
