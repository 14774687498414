<template>
    <div class="app-container">
      <nav class="top-nav">
        <ul>
          <li><a href="/labs">Labs</a></li>
          <li><a href="/users">Users</a></li>
        </ul>
        <button class="add-class-btn">Add Class</button>
      </nav>
  
      <section class="lab-form">
        <h2>Edit your Lab</h2>
        <form>
          <div class="form-group">
            <label for="className">Class Name</label>
            <input type="text" id="className" v-model="lab.className">
          </div>
  
          <div class="form-group">
            <label for="labName">Lab Name</label>
            <input type="text" id="labName" v-model="lab.name">
          </div>
  
          <!-- Repeat structure below for each note category -->
          <!-- Detail -->
          <div class="notes-category">
            <label>Detail</label>
            <!-- Slider component could be a custom Vue component or HTML range input -->
            <!-- Assuming custom Vue component named 'slider-component' -->
            <!--<slider-component v-model='lab.detailLevel'></slider-component>-->
            
            <!-- If using HTML range input instead: -->
            <!--<input type='range' id='detailSlider' v-model='lab.detailLevel'>-->
            
            <!-- Text area below slider -->
            <textarea v-model="lab.detailNotes" placeholder="Type here..."></textarea>
          </div>
  
          <!-- Questioning -->
          <div class="notes-category">
            <label>Questioning</label>
            <!-- Similar structure as Detail -->
          </div>
  
          <!-- Persistence -->
          <div class="notes-category">
            <label>Persistence</label>
            <!-- Similar structure as Detail -->
          </div>
  
          <!-- Save and Test buttons -->
          <button @click="saveLab">Save</button>
          <button @click="testLab">Test</button>
        </form>
      </section>
    </div>
  </template>

<script>
export default {
    name: 'ManageUsers'
}
</script>