<template>
  <div class="min-h-screen bg-gray-900 text-gray-200 flex flex-col">
    <main-content class="flex-1 p-6" :items="courseItems" :open-labs="openLabs" @toggle="toggle"></main-content>
    <div class="flex justify-center mb-10">
      <div class="addClassButton">
        <button class="ButtonClassAdd btn btn-accent" @click="addClass">Add Class</button>
      </div>
    </div>
  </div>
</template>

<script>
import MainContent from './MainContent.vue';
import axios from 'axios';

export default {
  name: 'TeacherViewAllClasses',
  components: {
    MainContent
  },
  data() {
    return {
      courseItems: [],
      openLabs: [],
    };
  },
  created() {
    // send a get request with axios to the backend and pass a certain teacherID in as a path param
    axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/teacher/michaelmcmahon/classes`)
      .then(response => {
        // whatever the response is from the backend, assign it to a scoped var in this component
        this.courseItems = response.data;
        // the below function will loop over the courseItems and fetch the labs for each course now that the courseItems have been fetched
        this.loopOverSubjectsForLabs();
      })
      .catch(error => {
        console.error("There was an error fetching the course items:", error);
      });
  },
  methods: {
    toggle(courseId) {
      const index = this.openLabs.indexOf(courseId);
      if (index === -1) {
        this.openLabs.push(courseId);
      } else {
        this.openLabs.splice(index, 1);
      }
    },
    async loopOverSubjectsForLabs() {
      for (let courseItem of this.courseItems) {
        courseItem.labs = await Promise.all(courseItem.labs.map(async (labId) => {
          try {
            const response = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${labId}`);
            return response.data;
          } catch (error) {
            console.error("There was an error fetching the labs:", error);
            return null;
          }
        }));
      }
      console.log(this.courseItems);
    },
    addClass() {
      this.$router.push({ name: 'addClass' });
    }
  }
}
</script>

<style scoped>
.addClassButton {
  width: 90%;
  display: flex;
  justify-content: center;
}
.ButtonClassAdd {
  width: 100%;
}
</style>