<template>
    <div class="labs-list">
      <h3 class="text-sm font-semibold mb-2">Labs</h3>
      <ul class="list-disc list-inside">
        <li @click="routeToViewLab(lab.id)" v-for="lab in labs" :key="lab.id" class="lab-item">
          <strong>{{ lab.labName }}</strong>: {{ lab.date }} due {{ lab.time }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: ['labs'],
    name: 'LabsList',
    methods: {
      routeToViewLab(id) {
        this.$router.push({ name: 'viewTeacherLab', params: { labID: id }});
      }
    }
  }

  </script>
  
  <style scoped>
  .lab-item {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    color: rgb(44, 201, 183);
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .lab-item:hover {
    background-color: #555;
  }
  
  .list-disc {
    padding-left: 1em;
  }
  
  .text-sm {
    font-size: 0.875rem; /* Small font size for subheadings */
  }
  
  .font-semibold {
    font-weight: 600; /* Semi-bold for better readability */
  }
  </style>

