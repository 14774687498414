<template>
  <div class="main-content flex-1 p-6">
    <div class="mb-6">
      <h2 class="yourClasses text-1xl font-bold text-center text-accent mb-6">Your Classes</h2>
      <div class="grid md:grid-cols-2 gap-4">
        <div v-for="item in items" :key="item.classID" class="card bg-gray-800 card-bordered">
          <div class="card-body">
            <h3 class="card-title">{{ item.classname }}</h3>
            <p>{{ item.classSubject }}</p>
            <p>{{ item.classDescription }}</p>
            <button @click="$emit('toggle', item.classID)" class="mt-4 btn btn-sm btn-accent">
              {{ isVisible(item.classID) ? 'Hide Labs' : 'Show Labs' }}
            </button>
            <transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
              <div v-if="isVisible(item.classID)">
                <labs-list class="cursor-pointer" :labs="item.labs"></labs-list>
              </div>
            </transition>
            <button @click="routeToViewClass(item.classID)" class="mt-4 btn btn-sm btn-accent">
              View Class
            </button>
          </div>
          <button @click="routeToAddLab(item)" class="bg-addLab mt-4 btn btn-sm btn-accent">
            Add Lab
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabsList from './LabsList.vue';

export default {
  props: ['items', 'openLabs'],
  components: {
    LabsList
  },
  methods: {
    isVisible(courseId) {
      return this.openLabs.includes(courseId);
    },
    beforeEnter(el) {
      el.style.height = '0';
      el.style.opacity = '0';
    },
    enter(el, done) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = '1';
      el.style.transition = 'height 0.3s ease, opacity 0.3s ease';
      done();
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = '1';
    },
    leave(el, done) {
      el.style.height = '0';
      el.style.opacity = '0';
      el.style.transition = 'height 0.3s ease, opacity 0.3s ease';
      done();
    },
    routeToViewClass(classID) {
      this.$router.push({ path: `/teacher/class/view/${classID}` });
    },
    routeToAddLab(item) {
      this.$router.push({ name: 'createLab', params: { classID: item.classID } });
    }
  }
}
</script>

<style>
.yourClasses {
  padding-bottom: 20px;
}
</style>
