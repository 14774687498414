<template>
    <div class="min-h-screen bg-gray-900 text-gray-200">
      <div class="container mx-auto p-6">
        <!-- Header -->
        <header class="flex justify-between items-center mb-8">
          <img src="./socratiqueLogo.png" alt="Socratique Logo" class="w-24">
          <h1 class="text-3xl font-bold">Socratique Dashboard</h1>
          <button class="btn btn-outline btn-accent">Log out</button>
        </header>
  
        <div class="grid md:grid-cols-12 gap-9 p-5">
          <!-- classes and Labs Column -->
          <div class="md:col-span-8">
            <!-- classes Section -->
            <div class="mb-6">
              <h2 class="text-1xl font-bold text-center text-accent mb-6">My Classes</h2>
              <div class="grid md:grid-cols-2 gap-4">
                <div @click="routeToViewThisClass(classItem)" v-for="classItem in classes" :key="classItem.id" class="card bg-gray-800 card-bordered">
                  <div class="card-body">
                    <h3 class="card-title">{{ classItem.classname }}</h3>
                    <p>Teacher: {{ classItem.teacher }}</p>
                  </div>
                </div>
                
              </div>
              <p class="cursor-pointer grid md:grid-cols-2 justify-center mt-5" @click="routeToViewClasses" style="color: var(--fallback-a,oklch(var(--a)/var(--tw-text-opacity)));">View All</p>
              <h2 class="cursor-pointer text-1xl font-bold text-right pr-4 text-accent mb-6" @click="addClass">+ Add Class</h2>
            </div>
  
            <!-- Labs Section -->
            <div class="labsSection md:col-span-8">
              <h2 class="text-1xl font-bold text-center text-accent mb-8">Active Labs</h2>
              <div class="overflow-x-auto">
                <table class="table w-full table-compact table-zebra bg-gray-800">
                  <thead>
                    <tr>
                      <th>Lab</th>
                      <th>Students Completed</th>
                      <th>Due Date</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Loop through labs -->
                    <tr v-for="lab in labs" :key="lab.id">
                      <td>{{ lab.labName }}</td>
                      <td>{{ lab.studentsCompleted.length }}</td>
                      <td>{{ lab.dueDateTime }}</td>
                      <td>
                        <button @click="viewLab(lab.id)" class="btn btn-sm btn-accent">View</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
  
          <!-- Right Sidebar -->
          <div class="rightSidebar md:col-span-4 md:row-span-2">
            <!-- Profile Card -->
            <div class="card bg-gray-800 card-bordered sidebar-card">
              <div class="card-body">
                <h2 class="card-title text-1xl font-bold text-accent mb-6">Profile</h2>
                <div class="flex items-center space-x-4 mb-3">
                  <div class="avatar">
                    <div class="w-16 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                      <img src="./profile.jpg">
                    </div>
                  </div>
                  <div>
                    <div class="font-bold">Instructor Name</div>
                    <div class="text-sm opacity-60">Company Name</div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Announcements Card -->
            <div class="card bg-gray-800 card-bordered sidebar-card">
              <div class="card-body">
                <h2 class="card-title text-1xl font-bold text-accent mb-6">Internal Announcements</h2>
                <div class="overflow-y-auto max-h-64">
                  <!-- Loop through announcements -->
                  <div v-for="announcement in announcements" :key="announcement.id" class="mb-3">
                    <div class="font-semibold">{{ announcement.title }}</div>
                    <p class="text-sm">{{ announcement.content }}</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
  
        <!-- Progress Tracking Section -->
        <div class="w-full p-10">
        <h2 class="text-2xl font-bold mb-4">Progress Tracking</h2>
        <div class="carousel w-full flex justify-center py-15">
        <div class="mt-16 px-4 py-6 bg-gray-800 rounded-lg shadow-md w-full max-w-6xl">
          <h2 class="text-2xl font-bold text-center text-accent mb-6">Progress Tracking</h2>
          <div class="carousel rounded-box w-full p-5 h-[350px]">
            <!-- Carousel Item 1: Course Completion Graph -->
            <div id="item1" class="carousel-item relative w-full h-full mt-8">
              <SparklineComponent
                title="Course Completion"
                :fill="fill"
                :gradient="selectedGradient"
                :line-width="lineWidth"
                :value="courseCompletionData"
                :padding="padding"
                :smooth="smooth"
              />
              <!-- Carousel Navigation -->
              <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a href="#item1" class="btn btn-circle">❯</a>
                <a href="#item3" class="btn btn-circle">❮</a>
                
              </div>
            </div>
                <!-- Carousel Item 2: Sales Data Graph -->
                <div id="item2" class="carousel-item relative w-full h-full">
                  <SparklineComponent
                    title="Overall Engagement"
                    :fill="fill"
                    :gradient="selectedGradient"
                    :line-width="lineWidth"
                    :value="overallEngagement"
                    :padding="padding"
                    :smooth="smooth"
                  />
                  <!-- Carousel Navigation -->
                  <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                    <a href="#item2" class="btn btn-circle">❮</a>
                    <a href="#item3" class="btn btn-circle">❯</a>
                  </div>
                </div>
  

                <div id="item3" class="carousel-item relative w-full h-full">
                  <SparklineComponent
                    title="Overall Understanding"
                    :fill="fill"
                    :gradient="selectedGradient"
                    :line-width="lineWidth"
                    :value="overallUnderstanding"
                    :padding="padding"
                    :smooth="smooth"
                  />
                  <!-- Carousel Navigation -->
                  <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                    <a href="#item2" class="btn btn-circle">❮</a>
                    <a href="#item1" class="btn btn-circle">❯</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import SparklineComponent from '../SparklineComponent.vue';
  import axios from 'axios';
  export default {
    name: 'TeacherDashboard',
    data() {
      return {
        overallEngagement: [0, 2, 5, 9, 5, 10, 9, 9, 9, 0, 1, 8, 2, 9, 0],
        overallUnderstanding: [50, 60, 70, 80, 90, 100, 110, 120],
        // Show/hide sidebar and classes
        showSidebar: true,
        showClasses: true,
        showLabs: true,
        // properties for sparklines
        fill: false,
        teacherID: 'michaelmcmahon',
        selectedGradient: ['#6fa8dc', '#42b983', '#2c3e50'],
        lineWidth: 2,
        padding: 8,
        smooth: true,
        classes: [],
        labsCompleted: [],
        labs: [],
        announcements: [
          { id: 1, title: 'Welcome to Socratique!', content: 'You can create your first class by clicking "Add Class" to start managing your learning with Socratique!' },


        ],
      };
    },
    components: {
      SparklineComponent,
    },
    methods:{
      routeToViewClasses(){
        this.$router.push({name: 'viewAllTeacherClass'});
      },
      addClass(){
        this.$router.push('/teacher/class/add');
      },
      viewLab(id){
        console.log({name: 'viewTeacherLab', params: { labID: id}});
        this.$router.push({name: 'viewTeacherLab', params: { labID: id}});
      },
      routeToViewThisClass(classItem){
        console.log("CLASS ID BEFORE ROUTE", classItem)
        this.$router.push({name: 'viewTeacherClass', params: { id: classItem.classID}});
      }
    },
    created() {
      axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/teacher/${this.teacherID}/classes`)
        .then(response => {
          this.classes = response.data;
          this.classes.forEach(classItem => {
            classItem.teacher = classItem.teacher[0]
            axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classItem.classID}/labs`)
              .then(response => {
                this.labs = response.data;
                this.labs.forEach(lab => {
                  lab.dueDateTime = new Date(lab.dueDateTime).toLocaleDateString();
                })
              })
              .catch(error => {
                console.error('Error fetching labs:', error);
              });
          });
        })
        .catch(error => {
          console.error('Error fetching labs:', error);
        });
  },
  
  };
  </script>
  
  
  <style scoped>
  .carousel {
    display: flex;
    justify-content: center;
  }
  
  .carousel-item {
    width: 100%; /* Make carousel items take full width */
    display: flex;
    justify-content: center;
  }
  
  .card {
    width: 90%; /* Increase the width of the card */
    margin: auto; /* Center the card */
  }
  .sidebar-card {
    margin-bottom: 1.5rem; /* Adjust this value for desired spacing */
  }
  .rightSidebar{
    height: 600px;
  }
  .labsSection{
  }
  </style>
  