<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="main-content flex-1 p-6">
      <div v-if="classData" class="class-details">
        <h2 class="text-1xl font-bold text-center text-accent mb-6">{{ classData.className }}</h2>
        <p><strong>Subject:</strong> {{ classData.classSubject }}</p>
        <p><strong>Description:</strong> {{ classData.classDescription }}</p>

        <div class="students mt-5">
          <h3 class="text-sm text-center font-semibold mb-2">Students</h3>
          <div class="flex flex-col items-end p-2">
            <p class=" flex flex-row text-blue-500"><svg-icon type="mdi" :path="path"></svg-icon>Share Class</p>
            <button class="mt-1 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200" @click="copyLink">Copy Link</button>
          </div>
          <h3 class="text-sm text-right pr-2">Class Code: {{ classData.classCode }} </h3>
          
          <ul class="list-disc list-inside">
            <li v-for="student in studentsData" :key="student.id" class="student-item ml-20 font-bold">
              <strong class="">{{ student.studentID }}</strong> {{ student.email }}
            </li>
          </ul>
        </div>

        <div class="students mt-5">
          <h3 class="text-sm text-center font-semibold mb-2 pb-5">Labs</h3>
          <ul class="lablist list-none p-0">
            <li v-for="lab in labs" :key="lab.id" class="lab-item bg-gray-800 p-4 mb-4 rounded-lg w-11/12 mx-auto flex items-center justify-between">
              <div class="flex-1">
                <strong class="block text-lg">{{ lab.labName }}</strong>
                <p class="text-sm"><strong>Due on: </strong>{{ lab.dueDateTime }}</p>
                <p class="text-sm">{{ lab.Notes }}</p>
              </div>
              <div class="flex space-x-2">
                <button @click="editLab(lab)" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200">Edit Lab</button>
                <button @click="viewAnalytics(lab.id)" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200">View Analytics</button>
              </div>
            </li>
          </ul>
          <div class="flex justify-center">
            <div class="addClassButton">
              <button class="ButtonClassAdd btn btn-accent" @click="addLab">Add Lab</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loading">
        Loading class details...
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiLinkVariant } from '@mdi/js'
import axios from 'axios';

export default {
  name: 'ViewClass',
  data() {
    return {
      classData: null,
      studentsData: [],
      shareLink: "https://socratique.io/join/",
      labs: [],
      path: mdiLinkVariant,
    };
  },
  components: {
		SvgIcon
	},

  async created() {
    const classId = this.$route.params.id;
    this.shareLink = this.shareLink + classId
    console.log('Class ID:', this.$route.params.id);
    try {
      const response = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classId}`);
      this.classData = response.data;
      console.log('Class details:', this.classData);
      const students = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classId}/students`);
      this.studentsData = students.data;
      console.log('Student details:', this.studentsData);
      const labPromises = this.classData.labs.map(labId => axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${labId}`));
      const labResponses = await Promise.all(labPromises);
      this.labs = labResponses.map(response => response.data);

      this.labs.forEach(lab => {
        const date = new Date(lab.dueDateTime);
        lab.dueDateTime = date.toLocaleDateString('en-US', { 
          weekday: 'long', 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short'
        });
      });
      console.log('Labs:', this.labs);
    } catch (error) {
      console.error('Error fetching class details:', error);
    }
  },
  methods:{
    addLab(){
      this.$router.push({name: 'createLab', params: {classID: this.$route.params.id}})
    },
    editLab(){
      // to be completed
    },
    viewAnalytics(labID){
      this.$router.push({name: 'viewTeacherLabProgress', params: {labID: labID}})
    },
    copyLink() {
      navigator.clipboard.writeText(this.shareLink)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    },
  }
}
</script>

<style scoped>
.students{
  height: 300px;
  background-color: rgb(26, 44, 60);
  border-radius: 20px;
  padding: 5px;
  width: 100%;
}
.addClassButton {
  width: 90%;
  display: flex;
  justify-content: center;

}
.ButtonClassAdd {
  width: 100%;
}

</style>
