<template>
    <div>
        <!-- Will this just be the dialogue interface? could maybe include their progress on that lab here -->
    </div>
</template>

<script>
export default {
    name: 'ViewTeacherLab'
}
</script>