import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import './assets/styles.css';
import { inject } from '@vercel/analytics';
 
inject();
// Import Vuetify components
import {
  VApp,
  VCard,
  VSlider,
  VTextField,
  VSpacer,
  VBtn,
  VMenu,
  VDatePicker,
  VCardTitle,
  VImg,
  VForm
} from 'vuetify/components';

const vuetify = createVuetify({
  components: {
    VApp,
    VCard,
    VSlider,
    VTextField,
    VSpacer,
    VBtn,
    VMenu,
    VDatePicker,
    VCardTitle,
    VImg,
    VForm
  }
});

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.mount('#app');
