<template>
    <div class="container">
      <nav>
        <ul>
          <li>Labs</li>
          <li>Users</li>
        </ul>
        <button class="menu-icon">☰</button>
      </nav>
  
      <aside>
        <ul class="class-list">
          <!-- This will be repeated for each class -->
          <li class="class-name">class name</li> 
        </ul>
      </aside>
  
      <section class="edit-class">
        <label for="className">Class Name</label>
        <input type="text" id="className" placeholder="enter name">
  
        <!-- The @click directive is used to listen to click events in Vue.js -->
        <button @click="addClass">Add Class</button> 
        <!-- A method named 'addClass' would be called when this button is clicked -->
  
        <!-- Similarly, 'deleteClass' would be called when this button is clicked -->
        <button @click="deleteClass" class="delete-button">Delete Class</button> 
      </section>
  
    </div>
  </template>

<script>
export default {
    name: 'EditClass'
}
</script>