<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="container mx-auto p-6">
      <!-- Header -->
      <header class="flex justify-between items-center mb-8">
        <img src="./socratiqueLogo.png" alt="Socratique Logo" class="w-24">
        <h1 class="text-3xl font-bold">Socratique Dashboard</h1>
        <button class="btn btn-outline btn-accent">Log out</button>
      </header>

      <div class="grid md:grid-cols-12 gap-9 p-5">
        <!-- classes and Labs Column -->
        <div class="md:col-span-8">
          <!-- classes Section -->
          <div class="mb-6">
            <h2 class="text-1xl font-bold text-center text-accent mb-6">My Classes</h2>
            <div class="grid md:grid-cols-2 gap-4">
              <div  @click="routeToViewThisClass(classItem)" v-for="classItem in classes" :key="classItem.id" class="card bg-gray-800 card-bordered">
                <div class="card-body">
                  <h3 class="card-title">{{ classItem.classname }}</h3>
                  <p>Teacher: {{ classItem.teacher }}</p>
                </div>
              </div>
              
            </div>
            <p class="cursor-pointer grid md:grid-cols-2 justify-center mt-5" @click="routeToViewClasses" style="color: var(--fallback-a,oklch(var(--a)/var(--tw-text-opacity)));">View All</p>
            <h2 class="cursor-pointer text-1xl font-bold text-right pr-4 text-accent mb-6" @click="joinClassOpen">+ Join Class</h2>
          </div>
          
          <!-- Labs Section -->
          <div class="labsSection md:col-span-8">
            <h2 class="text-1xl font-bold text-center text-accent mb-8">Active Labs</h2>
            <div class="overflow-x-auto">
              <table class="table w-full table-compact table-zebra bg-gray-800">
                <thead>
                  <tr>
                    <th>Lab</th>
                    <th>Teacher</th>
                    <th>Due Date</th>
                    <th>Class</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Loop through labs -->
                  <tr v-for="lab in labs" :key="lab.id">
                    <td>{{ lab.labName }}</td>
                    <td>{{ lab.teacherID }}</td>
                    <td>{{ lab.dueDateTime }}</td>
                    <td>{{ lab.className }}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
          <!-- Join Class Modal -->
          <div v-if="showJoinClassModal" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="modal-overlay absolute inset-0 bg-gray-800 opacity-75"></div>
            <div class="modal-container bg-gray-900 w-96 mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div class="modal-content py-4 text-left px-6">
                <div class="flex justify-between items-center pb-3">
                  <p class="text-2xl font-bold">Join Class</p>
                  <button class="modal-close cursor-pointer z-50" @click="closeJoinClassModal">
                    <svg @click="hideModal" class="fill-current text-white hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                      <path
                        d="M18 1.5L16.5 0 9 7.5 1.5 0 0 1.5 7.5 9 0 16.5 1.5 18 9 10.5 16.5 18 18 16.5 10.5 9z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="mb-4">
                  <label class="block text-white text-sm font-bold mb-2" for="classCode">Class Code</label>
                  <input
                    v-model="classCode"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                    id="classCode"
                    type="text"
                    placeholder="Enter class code"
                  />
                </div>
                <div class="flex justify-end">
                  <button
                    class="text-accent font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    @click="joinClassRequest"
                  >
                    Join
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Sidebar -->
        <div class="md:col-span-4 md:row-span-2">
          <!-- Profile Card -->
          <div class="card bg-gray-800 card-bordered sidebar-card">
            <div class="card-body">
              <h2 class="card-title text-1xl font-bold text-accent mb-6">Profile</h2>
              <div class="flex items-center space-x-4 mb-3">
                <div class="avatar">
                  <div class="w-16 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                    <img src="./profile.jpg">
                  </div>
                </div>
                <div>
                  <div class="font-bold">Student Name</div>
                  <div class="text-sm opacity-60">School Name</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Announcements Card -->
          <div class="card bg-gray-800 card-bordered sidebar-card">
            <div class="card-body">
              <h2 class="card-title text-1xl font-bold text-accent mb-6">Organisation Announcements</h2>
              <div class="overflow-y-auto max-h-64">
                <!-- Loop through announcements -->
                <div v-for="announcement in announcements" :key="announcement.id" class="mb-3">
                  <div class="font-semibold">{{ announcement.title }}</div>
                  <p class="text-sm">{{ announcement.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Completed Labs Card -->
          <div class="card bg-gray-800 card-bordered sidebar-card">
            <div class="card-body">
              <h2 class="card-title text-1xl font-bold text-accent mb-6">Completed Labs</h2>
              <table class="table w-full table-compact table-zebra bg-gray-800">
                <thead>
                  <tr>
                    <th>Lab</th>
                    <th>Teacher</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Loop through labs -->
                  <tr v-for="completedLab in completedLabs" :key="completedLab.id">
                    <td>{{ completedLab.name }}</td>
                    <td>{{ completedLab.teacher }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Progress Tracking Section -->
      <div class="w-full p-10">
      <h2 class="text-2xl font-bold mb-4">Progress Tracking</h2>
      <div class="carousel w-full flex justify-center py-15">
      <div class="mt-16 px-4 py-6 bg-gray-800 rounded-lg shadow-md w-full max-w-6xl">
        <h2 class="text-2xl font-bold text-center text-accent mb-6">Progress Tracking</h2>
        <div class="carousel rounded-box w-full p-5 h-[350px]">
          <!-- Carousel Item 1: Course Completion Graph -->
          <div id="item1" class="carousel-item relative w-full h-full mt-8">
            <SparklineComponent
              title="Course Completion"
              :fill="fill"
              :gradient="selectedGradient"
              :line-width="lineWidth"
              :value="courseCompletionData"
              :padding="padding"
              :smooth="smooth"
            />
            <!-- Carousel Navigation -->
            <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
              <a href="#item1" class="btn btn-circle">❯</a>
              <a href="#item3" class="btn btn-circle">❮</a>
              
            </div>
          </div>
              <!-- Carousel Item 2: Sales Data Graph -->
              <div id="item2" class="carousel-item relative w-full h-full">
                <SparklineComponent
                  title="Overall Engagement"
                  :fill="fill"
                  :gradient="selectedGradient"
                  :line-width="lineWidth"
                  :value="overallEngagement"
                  :padding="padding"
                  :smooth="smooth"
                />
                <!-- Carousel Navigation -->
                <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                  <a href="#item2" class="btn btn-circle">❮</a>
                  <a href="#item3" class="btn btn-circle">❯</a>
                </div>
              </div>

              <!-- Carousel Item 3: Visitor Stats Graph -->
              <div id="item3" class="carousel-item relative w-full h-full">
                <SparklineComponent
                  title="Overall Understanding"
                  :fill="fill"
                  :gradient="selectedGradient"
                  :line-width="lineWidth"
                  :value="overallUnderstanding"
                  :padding="padding"
                  :smooth="smooth"
                />
                <!-- Carousel Navigation -->
                <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                  <a href="#item2" class="btn btn-circle">❮</a>
                  <a href="#item1" class="btn btn-circle">❯</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SparklineComponent from '../SparklineComponent.vue';
import axios from 'axios';
export default {
  name: 'StudentDashboard',
  data() {
    return {
      overallEngagement: [0, 2, 5, 9, 5, 10, 9, 9, 9, 0, 1, 8, 2, 9, 0],
      overallUnderstanding: [50, 60, 70, 80, 90, 100, 110, 120],
      // Show/hide sidebar and classes
      showSidebar: true,
      showClasses: true,
      showLabs: true,
      // properties for sparklines
      fill: false,
      selectedGradient: ['#6fa8dc', '#42b983', '#2c3e50'],
      lineWidth: 2,
      studentID: "1hGYlw9U0qf594RVYlLV",
      padding: 8,
      smooth: true,
      showJoinClassModal: false,
      classes: [],
      labsCompleted: [],
      labs: [],
      announcements: [
        { id: 1, title: 'Welcome to Socratique!', content: 'If you got a class code or join link from your teacher this dashboard is where you can access all learning resources' },


      ],
      classCode: '',
    };
  },
  components: {
    SparklineComponent,
  },
  methods: {
    routeToViewClasses(){
      this.$router.push({name: 'viewAllStudentClass'});
    },
    routeToViewThisClass(classItem){
        console.log("CLASS ID BEFORE ROUTE", classItem)
        this.$router.push({name: 'viewStudentClass', params: { id: classItem.classID}});
    },
    joinClassOpen(){
      this.showJoinClassModal = true;
    },
    joinClassRequest(){
      axios.put(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/student/${this.studentID}/addClass/${this.classCode}`)
        .then(response => {
          console.log('Class joined successfully:', response.data);
          axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/student/${this.studentID}/classes`)
          .then(response => {
            this.classes = response.data;
            this.classes.forEach(classItem => {
              classItem.teacher = classItem.teacher[0]
              axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classItem.classID}/labs`)
                .then(response => {
                  this.labs = response.data;
                })
                .catch(error => {
                  console.error('Error fetching labs:', error);
                });
            });
          })
          .catch(error => {
            console.error('Error fetching labs:', error);
          });
          this.showJoinClassModal = false;
        })
        .catch(error => {
          console.error('Error joining class:', error);
        });
    },
    hideModal(){
      this.showJoinClassModal = false;
    },

  },
  created() {
    axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/student/${this.studentID}/classes`)
      .then(response => {
        this.classes = response.data;
        this.classes.forEach(classItem => {
          classItem.teacher = classItem.teacher[0]
          axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classItem.classID}/labs`)
            .then(response => {
              this.labs = response.data;
            })
            .catch(error => {
              console.error('Error fetching labs:', error);
            });
        });
      })
      .catch(error => {
        console.error('Error fetching labs:', error);
      });
  },

};
</script>


<style scoped>
.carousel {
  display: flex;
  justify-content: center;
}

.carousel-item {
  width: 100%; /* Make carousel items take full width */
  display: flex;
  justify-content: center;
}

.card {
  width: 90%; /* Increase the width of the card */
  margin: auto; /* Center the card */
}
.sidebar-card {
  margin-bottom: 1.5rem; /* Adjust this value for desired spacing */
}
</style>
