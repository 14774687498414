<template>
  <div class="main-container">
    <!-- Sidebar -->
    <div :class="['sideBarlistingClasses', { 'show-sidebar': showSidebar }]">
      <div class="sideBar">
        <button class="close-button" @click="toggleSidebar">Close</button>
        <div class="sideBarHeader">
          <!-- Sidebar Header Content -->
        </div>
        <div class="sideBarContent">
          <div
            class="class"
            v-for="(classItem, index) in classes"
            :key="`class-${index}`"
          >
            <h1 class="classTitle">{{ classItem.classname }}</h1>
            <div v-if="classItem.labs.length > 0">
              <h1 class="labsHeader">Labs</h1>
              <div
                class="lab"
                v-for="(lab, labIndex) in classItem.labs"
                :key="`lab-${labIndex}`"
              >
                <h2>{{ lab.labName }}</h2>
              </div>
            </div>
            <div v-else>
              <p>No labs available for this class.</p>
            </div>
          </div>
        </div>
        <!-- Message Counter Slider -->
        <div class="message-counter-container">
          <v-slider
            v-model="messageCount"
            style=" margin-top: 20px"
            :max="15"
            label="Messages"
            class="message-counter-slider custom-slider"
            readonly
          ></v-slider>
          <span style="font-weight: bold; margin-left: 5px">{{ messageCount }} / 15</span>
          <div class="trackerComponent" >
          <tracker-component :key="trackerKey"></tracker-component>
        </div>
        </div>
        <!-- Tracker Component -->
        
      </div>
    </div>
    <!-- Chat Container -->
    <div class="chat-container">
      <div class="chat-header">
        <button class="menu-button" @click="toggleSidebar" >Menu</button>
        <span class="header-title"><h1>{{ currentLabName }}</h1></span>
        <div
          class="progressButtonContainer"
          @click="openProgressTrackingComponent"
        >
          
        </div>
      </div>
      <div class="messages-container">
        <div
          v-for="message in messages"
          :key="`message-${message.id}`"
          :class="['message', message.role]"
        >
          <span class="message-content">{{ message.messageContent }}</span>
          <div class="message-details" :class="message.role + '-details'">
            <span class="message-sender">{{ message.role }}</span>
          </div>
        </div>
      </div>
      <div class="input-container">
        <input
          v-model="newMessage"
          type="text"
          placeholder="Type a message..."
          class="message-input"
           @keyup.enter="sendNewMessage"
        />
        <button @click="sendNewMessage" class="send-button">SEND</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TrackerComponent from './progressTracking/TrackerComponent.vue';

export default {
  name: 'DialogueComponent',
  props: {
    Chat: String,
  },
  data() {
    return {
      showProgressTracking: false,
      studentID: '84LS2xJM5PsmqfO8IPgk',
      currentChatID: '',
      newMessage: '',
      messages: [],
      classes: [],
      trackerKey: 0,
      currentAssistantMessage: null,
      newChatID: '',
      messageCount: 0,
      currentLabID: '',
      currentLabName: '', // Added to store the lab name
      showSidebar: false,
    };
  },
  components: {
    TrackerComponent,
  },
  async mounted() {
    // Your mounted logic here (if any)
  },
  async created() {
    this.currentChatID = this.$route.params.chatID;

    // Fetch lab and then make the PUT request once labID is available
    axios
      .get(
        `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/chat/${this.currentChatID}/lab`
      )
      .then((response) => {
        console.log(response.data, 'response');
        const functionLabID = response.data.labID;
        this.currentLabID = functionLabID; // Store the labID

        if (functionLabID) {
          // Now make the PUT request with the proper labID
          axios
            .put(
              `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${functionLabID}/startedProgress`,
              {
                studentID: this.studentID,
                chatID: this.currentChatID,
              }
            )
            .then(() => {
              console.log('Progress started for lab:', functionLabID);
            })
            .catch((error) => {
              console.error('Error starting lab progress:', error);
            });

          // Fetch lab details to get the lab name
          axios
            .get(
              `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${functionLabID}`
            )
            .then((labResponse) => {
              this.currentLabName = labResponse.data.labName;
            })
            .catch((error) => {
              console.error('Error fetching lab details:', error);
            });
        } else {
          console.error('labID is not available.');
        }
      })
      .catch((error) => {
        console.error('Error fetching lab:', error);
      });

    // Fetch messages
    axios
      .get(
        `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/chat/${this.currentChatID}/messages`
      )
      .then((response) => {
        this.messages = response.data;
        this.updateMessageCount();
      })
      .catch((error) => {
        console.error('Error fetching messages:', error);
      });

    // Fetch classes and their labs
    axios
      .get(
        `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/student/${this.studentID}/classes`
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.classes = response.data.map((classItem) => ({
            ...classItem,
            labs: [],
          }));

          return Promise.all(
            this.classes.map((classItem) =>
              axios
                .get(
                  `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/class/${classItem.classID}/labs`
                )
                .then((labResponse) => {
                  classItem.labs = labResponse.data.map((lab) => ({
                    id: lab.id,
                    classID: lab.classID,
                    teacherID: lab.teacherID,
                    chatID: lab.chatID,
                    lessonContent: lab.lessonContent,
                    questioning: lab.questioning,
                    labsID: lab.labID,
                    studentsCompleted: lab.studentsCompleted,
                    labName: lab.labName,
                    persistence: lab.persistence,
                    detail: lab.Detail,
                  }));
                })
                .catch((labError) => {
                  console.error(
                    'Error fetching labs for class',
                    classItem.classID,
                    labError
                  );
                })
            )
          );
        }
      })
      .then(() => {
        console.log('All labs loaded for all classes:', this.classes);
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
      });
  },

  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    updateMessageCount() {
      if (this.messageCount >= 15) {
        axios.post(
          `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${this.currentLabID}/complete`,
          {
            studentID: this.studentID,
            chatID: this.currentChatID,
          }
        );
      }
      // Filter messages where 'role' is 'user' and update the message count
      this.messageCount = this.messages.filter(
        (message) => message.role === 'user'
      ).length;
    },
    openProgressTrackingComponent() {
      this.showProgressTracking = !this.showProgressTracking;
    },
    async sendNewMessage() {
      if (this.newMessage.trim() !== '') {
        const TextFromBox = this.newMessage;
        this.newMessage = '';
        this.messages.push({
          messageContent: TextFromBox,
          messageNumber: this.messages.length + 1,
          role: 'user',
        });

        this.messageCount++; // Increment message count only for user messages

        try {
          const response = await axios.post(
            'https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/chat',
            {
              message: TextFromBox,
              ChatID: this.currentChatID,
            }
          );

          const { sessionId } = response.data;
          const eventSource = new EventSource(
            `https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/chat/stream/${sessionId}`
          );

          this.currentAssistantMessage = {
            messageContent: '',
            messageNumber: this.messages.length + 1,
            role: 'Socratique',
          };
          this.messages.push(this.currentAssistantMessage);

          eventSource.addEventListener('message', (event) => {
            if (event.data === '[DONE]') {
              eventSource.close();
              this.trackerKey += 1; // Update tracker key to force re-mount
              this.currentAssistantMessage.messageContent = this.cleanMessage(
                this.currentAssistantMessage.messageContent
              );
              this.currentAssistantMessage = null; // Reset the current assistant message
              return;
            }

            try {
              const data = JSON.parse(event.data);
              if (data.content) {
                this.currentAssistantMessage.messageContent += data.content;
              }
            } catch (error) {
              console.error('Error parsing message data:', error);
            }
          });

          eventSource.addEventListener('error', (error) => {
            console.error('EventSource error:', error);
            eventSource.close();
            this.currentAssistantMessage = null; // Reset the current assistant message
          });
        } catch (error) {
          console.error('Communication error:', error);
        }
      }
    },
    cleanMessage(message) {
      const index = message.toLowerCase().indexOf('engagement');
      return index !== -1 ? message.substring(0, index).trim() : message;
    },
  },
};
</script>

<style scoped>
/* Main container setup */
.main-container {
  position: relative;
  display: flex;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: #add8e6; /* Light blue background */
  height: 100vh;
  overflow: hidden; /* Prevent scrolling when sidebar is open */
}

/* Sidebar styling */
.sideBarlistingClasses {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%; /* Adjust width for mobile devices */
  height: 100%;
  z-index: 1000;
  background-color: #add8e6; 
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%); /* Sidebar is off-screen by default */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

@media (min-width: 600px) {
  .sideBarlistingClasses {
    width: 30%; /* Adjust width for larger devices */
  }
}

/* When the sidebar is visible */
.sideBarlistingClasses.show-sidebar {
  transform: translateX(0);
}

/* Close button for sidebar */
.close-button {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

/* Menu button styling */
.menu-button {
  display: block;
  background-color: #003366;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 20px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

/* Chat container styling */
.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #add8e6; /* Light blue background */
  overflow: hidden; /* Prevent scrolling when sidebar is open */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.sideBarlistingClasses.show-sidebar ~ .chat-container {
  pointer-events: none; /* Disable interactions with chat when sidebar is open */
}

.sideBarlistingClasses.show-sidebar ~ .chat-container * {
  pointer-events: none;
}


/* Class styling */
.class {
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
  cursor: pointer;
  width: 95%;
}

.classTitle {
  font-size: 20px;
  font-weight: bold;
}

/* Labs styling */
.labsHeader {
  font-weight: 600;
}

.lab h2 {
  font-size: 18px;
  margin-left: 10px;
}

/* Message Counter Container */
.message-counter-container {
  color: black;
  padding: 10px;
}

/* Tracker Component styling */
.trackerComponent {
  width: 100%;
  padding: 10px;
}

/* Sidebar header styling */
.sideBarTitle {
  font-size: 20px;
  font-weight: bold;
}

.sideBarHeader {
  padding: 10px;
}

/* Chat header styling */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.header-title {
  font-weight: bold;
  font-size: 20px;
  margin-right: 50px;
  color:rgb(255, 255, 255);
  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
 padding-bottom: 15px;
 border-radius: 10px;
  background-color:#d49185;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  
}

/* Message container styling */
.messages-container {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  animation: fadeIn 0.5s ease-in-out;

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message {
  margin-bottom: 20px;
}

.Socratique {
  text-align: left;
}

.user {
  text-align: right;
}

/* Message content styling */
.message-content {
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  word-break: break-word;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
  
}

.Socratique .message-content {
  margin-right: 20px;
  font-weight: 520;
  color: #333;
  font-size: 20px;
}

.user .message-content {
  background-color: #407bb6; /* Dark blue for user messages */
  color: white;
}

/* Message details styling */
.message-details {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.user-details {
  justify-content: flex-end;
}

.message-sender {
  font-size: 16px;
  color: #bbb;
}

/* Input container styling */
.input-container {
  display: flex;
  padding: 16px;
}

/* Message input styling */
.message-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 8px;
  margin-left: 200px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 20px;
  background-color: white;
  font-size: 16px;
}


/* Send button styling */
.send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #003366;
  color: white;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-right: 200px;
  margin-bottom: 10px;
}

.send-button:hover {
  background-color: #002244;
}

/* Scrollbar styling for messages container */
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: #003366;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Adjustments for mobile devices */
@media (max-width: 599px) {
  /* Sidebar covers full screen on mobile devices */
  .sideBarlistingClasses {
    width: 100%;
  }

  /* Adjust font sizes for better readability on small screens */
  .classTitle,
  .header-title,
  .message-sender {
    font-size: 18px;
  }

  .message-content {
    font-size: 16px;
  }
  .send-button{
    margin-right:8px;
  }
  .message-input {
    font-size: 14px;
    margin-left: 8px;
  }
}

/* Prevent scrolling on body when sidebar is open */
body.sidebar-open {
  overflow: hidden;
}
</style>
