<template>
  <div class="labs-list">
    <h3 class="text-sm font-semibold mb-2">Labs</h3>
    <div v-if="labs && labs.length">
      <ul class="list-disc list-inside">
        <li v-for="lab in labs" :key="lab.id" class="lab-item">
          <div class="lab-info">
            <strong>{{ lab.labName }}</strong>: {{ lab.date }} due {{ lab.time }} - {{ lab.teacherID }}
          </div>
          <button @click="startProgress(lab)" class="start-btn">Start Progress</button>
        </li>
        
      </ul>
      
    </div>
    <div v-else class="empty-labs flex justify-center items-center h-64">
      <p class="text-sm max-w-32 text-center text-gray-500">No labs available at the moment. Please check back later.</p>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['labs'],
  name: 'LabsList',
  mounted() {
  },
  methods: {
    async startProgress(lab) {
      const studentID = localStorage.getItem('currentStudentID');
      const newChat = await axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/addChat', {
        chatID: '',
        labID: lab.id,
        engagementScore: 0,
        understandingScore: 0,
        dialogue: []
      });
      
      const passChatID = newChat.data.chatID;
      axios.put(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${lab.id}/chat`, {
        chatID: passChatID
      });
      console.log('New chat:', passChatID);
      axios.put(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${lab.id}/addStudent`, {
        studentID: studentID,
        chatID: passChatID
      });
      localStorage.setItem('newChatID', passChatID);
      this.$router.push({ name: 'dialogue' });
    }
  }
}
</script>

<style scoped>
.lab-item {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Aligns the lab info and button on opposite ends */
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  color: rgb(44, 201, 183);
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.lab-item:hover {
  background-color: #ffffff0b;
}

.lab-info {
  flex: 1; /* Takes up the maximum available space, pushing the button to the edge */
}

.start-btn {
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.375rem;
  background-color: rgba(59, 229, 209, 0.505); /* Example button color */
  transition: background-color 0.2s;
  cursor: pointer;
}

.start-btn:hover {
  background-color: #249ea9; /* Darker shade on hover */
}

.list-disc {
  padding-left: 1em;
}

.text-sm {
  font-size: 0.875rem; /* Small font size for subheadings */
}

.font-semibold {
  font-weight: 600; /* Semi-bold for better readability */
}

.empty-labs {
  min-height: 200px; /* Ensure it has a presence even when empty */
}
</style>
