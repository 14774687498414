<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  mounted() {
    localStorage.setItem('currentStudentID', '1hGYlw9U0qf594RVYlLV');
    console.log(this.$route.query.lab)
    if(this.$route.query.lab !== undefined){
      this.$router.push({name: 'login', query: {lab: this.$route.query.lab}})
    }

  }
}
</script>

<style>
html{
  background-color: rgb(60, 60, 60);
}
</style>