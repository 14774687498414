<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="main-content flex-1 p-6">
      <div v-if="studentsData.length > 0" class="class-details">
        <p><strong>Students Completed:</strong> {{ studentsData.length }}</p>
        <div class="labs mt-5">
          <h3 class="text-sm text-center font-semibold mb-2 pb-5">Students</h3>
          <ul class="list-none p-0">
            <li v-for="student in studentsData" :key="student.student.id" class="lab-item bg-gray-800 p-4 mb-4 rounded-lg w-11/12 mx-auto flex flex-col">
              <div @click="toggleDetails(student.student.id)" class="cursor-pointer flex justify-between items-center">
                <div>
                  <strong class="block text-lg">{{ student.student.firstName + ' ' + student.student.lastName }}</strong>
                  <p class="text-sm"><strong>Email: </strong>{{ student.student.email }}</p>
                </div>
                <span class="text-accent">{{ detailsVisible[student.student.id] ? 'Hide' : 'Show' }}</span>
              </div>
              <div v-if="detailsVisible[student.student.id]" class="mt-2 bg-gray-700 p-3 rounded">
                <p><strong>Engagement:</strong> {{ student.chat.engagement }}</p>
                <p><strong>Understanding:</strong> {{ student.chat.understanding }}</p>
                <div><strong>Transcription:</strong></div>
                <ul class="transcription-list">
                  <li v-for="(message, index) in student.chat.transcription" :key="index">
                    <span class="message-role">{{ message.role }}:</span>
                    <span class="message-content">{{ message.messageContent }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="text-center mt-5">Loading or No data available</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ViewTeacherLabProgress',
  data() {
    return {
      studentsData: [],
      detailsVisible: {},
    };
  },
  async mounted() {  
    const labID = this.$route.params.labID;
    try {
      const response = await axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${labID}/analytics`);
      this.studentsData = response.data;
      this.studentsData.forEach(student => {
        this.detailsVisible[student.student.id] = false;
      });
    } catch (error) {
      console.error('Error fetching class details:', error);
    }
  },
  methods: {
    toggleDetails(id) {
      console.log(`Toggling details for ID: ${id}`);  // Debugging log
      if (this.detailsVisible[id] === undefined) {
        this.detailsVisible[id] = true; // Initialize if not already defined
      } else {
        this.detailsVisible[id] = !this.detailsVisible[id]; // Toggle the visibility
      }
      console.log(this.detailsVisible);  // Check the state after toggling
    }
  }
}
</script>



<style scoped>
.students {
  height: 300px;
  background-color: rgb(26, 44, 60);
  border-radius: 20px;
  padding: 5px;
  width: 100%;
}
.transcription-list {
  background: #333;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}
.message-role {
  font-weight: bold;
  color: #ccc;
}
.message-content {
  margin-left: 5px;
}
</style>
