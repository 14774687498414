<template>
  <div class="login-container flex justify-center items-center h-screen bg-gradient-to-r from-gray-800 via-gray-900 to-black">
    <v-card class="login-card w-96 p-8">
      <v-img src="../assets/socratiqueLogo.png" class="logo mx-auto mb-6"></v-img>
      <h1 class="title text-center text-4xl font-bold text-white mb-8">Socratique</h1>
      <v-card-title class="card-title flex justify-between items-center mb-4">
        <h2 class="text-2xl text-white">{{ isLogin ? 'Sign In' : 'Sign Up' }}</h2>
      </v-card-title>

      <v-form @submit.prevent="isLogin ? signIn() : signUp()">
        <div class="mb-4">
          <label class="block text-white text-sm font-bold mb-2" for="email">Email</label>
          <input
            ref="emailInput"
            v-model="email"
            class="shadow appearance-none rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            id="classCode"
            type="text"
            placeholder="Enter your email..."
          />
        </div>
        <div class="mb-4">
          <label class="block text-white text-sm font-bold mb-2" for="email">Password</label>
          <input
            v-model="password"
            class="shadow appearance-none rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            id="classCode"
            type="password"
            placeholder="Enter your password..."
          />
        </div>
        <v-btn type="submit" class="btn-primary w-full py-3">{{ isLogin ? 'Sign In' : 'Sign Up' }}</v-btn>
        <v-btn class="btn-google w-full py-3 mt-4" @click="signInWithGoogle">Sign In with Google</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginPage',
  data() {
    return {
      isLogin: true,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      validCreds: {
        teacher: 'teacher',
        student: 'student'
      },
      labIDToRouteTo: '',
    };
  },
  created(){
    if(this.$route.query.lab){
      console.log(this.labIDToRouteTo)
      this.labIDToRouteTo = this.$route.query.lab;
    }
  },
  methods: {
    signIn() {
      const { email, password, validCreds } = this;
      const username = email.split('@')[0];
      if (validCreds[username] && validCreds[username] === password) {
        if(this.labIDToRouteTo !== '' && username !== 'teacher'){
          axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/addChat', {
            chatID: '',
            labID: this.labIDToRouteTo,
            engagementScore: 0,
            understandingScore: 0,
            dialogue: []
          }).then(response => {
            var tempChatID = response.data.chatID;
            axios.put(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/lab/${this.labIDToRouteTo}/addStudent`,{
              studentID: username,
              chatID: tempChatID
            })
            this.$router.push({ name: 'dialogue', params: { chatID: tempChatID }});
          });
        }
        this.$router.push(username === 'teacher' ? '/teacher/dashboard' : '/student/dashboard');
      } else {
        alert('Invalid credentials');
      }

    },
    signUp() {
      alert('Sign up functionality is not implemented yet.');
    },
    signInWithGoogle() {
      alert('Google sign in functionality is not implemented yet.');
    }
  },
  mounted() {
    this.$refs.emailInput.focus(); 
  }
};
</script>

<style scoped>
.login-container {
  @apply flex justify-center items-center h-screen bg-gradient-to-r from-gray-800 via-gray-900 to-black;
}
.login-card {
  @apply p-8 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 border border-gray-700 shadow-xl rounded-lg;
}
.logo {
  @apply mx-auto mb-6;
}
.title {
  @apply text-center text-4xl font-bold text-white mb-8;
}

.card-title {
  @apply flex justify-between items-center mb-4;
}
.btn-primary {
  @apply w-full py-3 bg-blue-500 hover:bg-blue-700 text-white;
}
.btn-google {
  @apply w-full py-3 mt-4 bg-red-500 hover:bg-red-700 text-white;
}
.white-input .v-field__control, .white-input .v-field__input {
  color: white;  /* Ensures both the label and input text are white */
}
</style>
