<template>
  <div class="trackerComponentContainer">
    <div v-if="isLoading" class="loadingIcon"></div>
    <Knowledgegraph
      v-if="showKnowledge && !isLoading"
      title="Knowledge"
      :fill="fill"
      :gradient="selectedGradient"
      :line-width="lineWidth"
      :value="overallKnowledge"
      :padding="padding"
      :smooth="smooth"
      :show-xaxis="true"
      class="knowledgeGraph"
    />
    <p v-if="!showKnowledge && !isLoading">{{ knowledgePlaceholderText }}</p>
    
    <EngagementGraph
      v-if="showEngagement && !isLoading"
      title="Engagement"
      :fill="fill"
      :gradient="selectedGradient"
      :line-width="lineWidth"
      :value="overallEngagement"
      :padding="padding"
      :smooth="smooth"
      :show-xaxis="true"
      class="engageGraph"
    />
    <p v-if="!showEngagement && !isLoading">{{ engagePlaceholderText }}</p>

    <div v-if="isEvaluating" class="loadingIcon"></div>
    <div v-if="!isEvaluating && currentEvaluation" v-html="formattedEvaluation" class="evaluationContainer"></div>
    <p v-if="!isEvaluating && !currentEvaluation">{{ evaluationPlaceholderText }}</p>
  </div>
</template>

<script>
import EngagementGraph from './EngagementGraph.vue';
import Knowledgegraph from './KnowledgeGraph.vue';
import axios from 'axios';

export default {
  name: 'TrackerComponent',
  components: {
    EngagementGraph,
    Knowledgegraph
  },
  data() {
    return {
      currentChatID: '',
      overallEngagement: [],
      overallKnowledge: [],
      showEngagement: false,
      showKnowledge: false,
      isLoading: true,
      isEvaluating: true,
      engagePlaceholderText: 'Start chatting with Socratique to see your engagement throughout the conversation!',
      knowledgePlaceholderText: 'Start chatting with Socratique to see your knowledge throughout the conversation!',
      evaluationPlaceholderText: 'Continue chatting with Socratique to start evaluating your chat performance...',
      goodFeedbackArray: ['You were very engaged', 'You asked good questions', 'You were very focused'],
      constructiveFeedbackArray: ['You could have asked more questions', 'You could have been more focused', 'You could have been more engaged'],
      fill: false,
      selectedGradient: ['#6fa8dc', '#42b983', '#2c3e50'],
      lineWidth: 2,
      padding: 8,
      smooth: true,
      currentEvaluation: '',
    };
  },
  computed: {
    formattedEvaluation() {
      return this.currentEvaluation
        .replace(/### (.+)/g, '<strong>$1</strong>') // Bold headers
        .replace(/\n/g, '<br>'); // Line breaks
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.currentChatID = localStorage.getItem('newChatID');
      axios.all([
        axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/chat/${this.currentChatID}/engagementScores`),
        axios.get(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/chat/${this.currentChatID}/understandingScores`)
      ])
      .then(axios.spread((engagementResponse, knowledgeResponse) => {
        this.overallEngagement = engagementResponse.data;
        this.overallKnowledge = knowledgeResponse.data;

        this.showEngagement = this.overallEngagement.length > 1;
        this.engagePlaceholderText = this.overallEngagement.length === 0
          ? 'Start chatting with Socratique to see your engagement throughout the conversation!'
          : 'Continue chatting with Socratique to see your engagement throughout the conversation!';

        this.showKnowledge = this.overallKnowledge.length > 1;
        this.knowledgePlaceholderText = this.overallKnowledge.length === 0
          ? 'Start chatting with Socratique to see your knowledge throughout the conversation!'
          : 'Continue chatting with Socratique to see your knowledge throughout the conversation!';

        this.isLoading = false;
        this.isEvaluating = false;

        // Call evaluate method after fetching data
        if (this.overallKnowledge.length > 1){
          this.evaluate();
        }
        
      }))
      .catch(error => {
        console.error(error);
        this.isLoading = false;
        this.isEvaluating = false;
      });
    },
    evaluate() {
      this.isEvaluating = true;
      axios.post(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/evaluate`, {
        ChatID: this.currentChatID
      })
      .then(response => {
        this.currentEvaluation = response.data.content;
        this.isEvaluating = false;
      })
      .catch(error => {
        console.error(error);
        this.isEvaluating = false;
      });
    }
  }
};
</script>

<style>
.trackerComponentContainer {
  display: grid;
  grid-template-rows: auto auto auto; 
  grid-gap: 20px; 
  padding: 20px;
  width: 100%; 
  align-items: start; 
}

.loadingIcon {
  width: 50px;
  height: 50px;
  border: 4px solid grey;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.carousel-item {
  width: 90%; 
}

.carousel-item.feedback {
  display: flex;
  flex-direction: column;
  align-items: start; 
  padding: 20px; 
}

.carousel-item.feedback h3 {
  font-weight: bold; 
  margin-top: 0; 
}

.feedback-list {
  list-style-type: none; 
  padding: 0;
  margin-top: 10px; 
  width: 100%; 
}

.feedback-list li {
  word-break: break-word; 
}

.knowledgeGraph {
  transform: scale(0.95);
}

.engageGraph {
  transform: scale(0.95);
}

.evaluationContainer {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  word-wrap: break-word;
}
</style>
