<template>
  <div class="min-h-screen bg-gray-900 text-gray-200">
    <div class="main-content flex-1 p-6">
      <div class="mb-4">
        <h2 class="yourClasses text-1xl font-bold text-center text-accent mb-1">Add Class</h2>
        
        <transition-group name="fade" tag="div">
          <div v-for="(field, index) in fields" :key="field.key" :style="{ animationDelay: `${index * 0.5}s` }"
               :class="{ 'form-field-disabled': field.disabled }" class="form-field">
            <label class="field-label">{{ field.label }}</label>
            <div class="separator" :class="{ 'wide-separator': field.key === 'description' }"></div>
            <component :is="field.component"
                       :disabled="field.disabled"
                       :class="['input', 'input-bordered', field.key === 'description' ? 'description' : '']"
                       :placeholder="field.placeholder"
                       v-model="formValues[field.key]"
                       @input="handleInput(field.key, $event.target.value)" />
          </div>
        </transition-group>
      </div>
      <div class="addClassButton">
        <button :disabled="isAddButtonDisabled" class="btn btn-accent w-full" @click="addClass">Add Class</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddClass',
  data() {
    return {
      fields: [
        { key: 'name', label: 'Give your class a name...', component: 'input', placeholder: 'Class Name', disabled: false },
        { key: 'subject', label: 'Select the subject of your class...', component: 'input', placeholder: 'Class Subject', disabled: true },
        { key: 'description', label: 'Give a brief description of your class...', component: 'textarea', placeholder: 'Class Description', disabled: true }
      ],
      formValues: {
        name: '',
        subject: '',
        description: ''
      },
      teacherID: 'michaelmcmahon'
    };
  },
  computed: {
    isAddButtonDisabled() {
      // Check if any required field is empty to disable the button
      return Object.values(this.formValues).some(value => !value.trim());
    }
  },
  methods: {
    handleInput(fieldKey, newValue) {
      this.formValues[fieldKey] = newValue;
      this.updateFieldStatus();
    },
    updateFieldStatus() {
      // Enable or disable the subject and description fields based on current form values
      this.fields[1].disabled = !this.formValues.name.trim();
      if (this.fields[1].disabled) {
        this.formValues.subject = '';
      }
      this.fields[2].disabled = !this.formValues.subject.trim();
      if (this.fields[2].disabled) {
        this.formValues.description = '';
      }
    },
    async addClass() {
      const payload = {
        ClassID: '', 
        Students: [],
        Teachers: [this.teacherID],
        Labs: [],
        ClassName: this.formValues.name,
        ClassSubject: this.formValues.subject,
        ClassDescription: this.formValues.description
      };

      try {
        var response = await axios.post('https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/addClass', payload);
        console.log('Class added successfully:', response.data);
        
        const newClassId = response.data.id;
        
        const classesResponse = await axios.put(`https://socratiqueapi-uat-btd2b7azhefshygx.northeurope-01.azurewebsites.net/teacher/${this.teacherID}/addClass/${newClassId}`);

        console.log('Updated Teacher successfully!', classesResponse.data);

        this.$router.push({ name: 'viewTeacherClass', params: { id: newClassId } });
        
        this.clearForm();
      } catch (error) {
        console.error('Error adding class:', error);
      }
    },
    clearForm() {
      this.formValues.name = '';
      this.formValues.subject = '';
      this.formValues.description = '';
      this.updateFieldStatus();
    }
  },
  mounted() {
    this.updateFieldStatus(); 
  }
}
</script>

<style scoped>
.separator {
  margin: 1rem auto;
  height: 5px;
  background-color: rgb(44, 201, 183);
  border-radius: 30px;
  width: 0;
  animation: slideIn 0.8s ease forwards;
}

@keyframes slideIn {
  from { width: 0; }
  to { width: 92%; }
}

.fade-move {
  transition: transform 0.5s;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.input {
  border-style: dashed;
  background-color: transparent;
  margin-top: 0.5rem;
  margin-left: 4%;
}

.description {
  width: 90%;
  height: 20rem;
  padding-top: 10px;
}

.form-field {
  margin-bottom: 1rem;
}

.field-label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-field-disabled .input {
  background-color: #dddddd0d; 
  cursor: not-allowed;
}

.form-field-disabled label {
  color: #ffffff; 
}

.btn:disabled {
  background-color: #666; 
  cursor: not-allowed;
}
</style>
